<mat-form-field class="w-full" [appearance]="appearance">
  <mat-label [class]="css" *ngIf="label">{{ label }}</mat-label>

  <input
    [max]="max"
    [min]="min"
    type="hidden"
    [(ngModel)]="value"
    [disabled]="disabled"
    (click)="data.open()"
    [matDatepicker]="data"
  />

  <input
    matInput
    name="data"
    id="{{ id }}"
    [required]="required"
    [disabled]="disabled"
    [textMask]="{ mask: mask }"
    placeholder="{{ placeholder }}"
    (change)="toDate($event.target.value)"
    [ngModel]="value | date : 'dd/MM/yyyy'"
  />

  <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>

  <mat-datepicker #data></mat-datepicker>
</mat-form-field>
