import { Inject, OnInit, Component, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import moment from 'moment';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { FotoComponent } from './foto/foto.component';
import { CampanhaService } from '../../../../campanha.service';
import { GerenciadorService } from '../../../gerenciador.service';
import { ResumoCursosComponent } from './resumo-cursos/resumo-cursos.component';
import { FuseUtilsService } from '@fuse/services/utils';
import { ResumoCursosService } from './resumo-cursos/resumo-cursos.service';
import { AppService } from 'app/app.service';
import { optionsLabelValue } from 'app/core/utils/utils';
import { SelectMunicipioService } from 'app/shared/components/select-municipio/select-municipio.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'notes-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsComponent implements OnInit, AfterViewInit {
  cadeias: any;
  cliente: any;
  ocultar = [];
  campanha: any;
  promotor: any;
  faegJovem: any;
  respostas = {};
  formulario = [];
  formweb = false;
  editMode = false;
  lista: any[] = [];
  leitura: any = false;
  selecionado: any = {};
  progressos: any[] = [];
  municipios = [];
  txtMunicipio = '';
  controlMunicipio = new FormControl();
  vinculoMeioRural = [];
  escolaridades = [];
  participantesSemVinculo: boolean = false;

  constructor(
    private _dialog: MatDialog,
    private _matDialog: MatDialog,
    private _utils: FuseUtilsService,
    private _change: ChangeDetectorRef,
    private _resumo: ResumoCursosService,
    @Inject(MAT_DIALOG_DATA) public data, // data: CAMPANHA_PROGRESSO
    private _campanhaService: CampanhaService,
    private _gerenciadorService: GerenciadorService,
    private _matDialogRef: MatDialogRef<DetailsComponent>,
    private _appService: AppService,
    private _municipios: SelectMunicipioService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.formweb = this.data?.formweb;
    this.campanha = this.data?.campanha;

    if (this.campanha.tipo !== 'Telemarketing') {
      this.formulario = this.data?.campanha?.perguntas;

      this.leitura = ['Presencial', 'Evento'].includes(this.campanha?.tipo);

      const padroes = this.data?.resposta
        ?.map((res) => {
          if (res?.pergunta?.tipo === 'padrao') {
            return res?.pergunta;
          }
        })
        .filter((res: any) => res);

      if (padroes) {
        this.formulario = padroes.concat(this.formulario);
      }

      this.data?.resposta?.map((res) => {
        if (res?.pergunta) {
          this.respostas[res?.pergunta?.id] = res?.resposta;
        }
      });
    } else {
      this.checarTelemarketing();

      this._resumo.lista$.subscribe((lista) => {
        this.lista = lista;
      });

      this._resumo.progressos$.subscribe((progressos) => {
        this.progressos = progressos;
      });
    }

    this._campanhaService.cadeias$.subscribe((res) => {
      this.cadeias = res;

      this._change.markForCheck();
    });

    this.cliente = this.data?.pessoa;
    if (this.cliente.municipio) {
      this.municipios = [this.cliente.municipio]
      this.controlMunicipio.patchValue(this.cliente.municipio.descricao + ' - ' + this.cliente.municipio.uf)
    }
    this.promotor = this.data?.promotor;
    this.faegJovem = this.data?.promotor?.faegJovem;

    this._appService.vinculoMeioRural$
      .subscribe({
        next: vinculoMeioRural => {
          this.vinculoMeioRural = optionsLabelValue(vinculoMeioRural, 'descricao');
        },
        error: error => {
          console.log(error);
        }
    })

    this._appService.escolaridade$
      .subscribe({
        next: escolaridade => {
          this.escolaridades = escolaridade;
        },
        error: error => {
          console.error(error);
        }
     })
  }

  ngAfterViewInit(): void {
    this._municipios
      .completeMunicipio(this.controlMunicipio.valueChanges)
      .subscribe((resp) => {
        if (resp?.length) {
          this.municipios = resp?.slice(0, 20);

          this._changeDetectorRef.markForCheck();
        }
      });
  }

  activeEdit(): void {
    this.editMode = true;
  }

  cancelEdit(): void {
    this.editMode = false;
  }

  saveEdit(): void {
    this.editMode = false;

    this._campanhaService.salvarNovaPessoa(this.cliente).subscribe(
      () => {},
      () => {
        Swal.fire({
          timer: 2000,
          toast: true,
          icon: 'error',
          timerProgressBar: true,
          showConfirmButton: false,
          text: 'Erro ao atualizar pessoa !',
        });
      }
    );

    lastValueFrom(
      this._gerenciadorService.updateListGerenciador(this.data?.id, {
        pessoa: this.cliente,
      })
    ).then();
  }

  getData(fim: string): string {
    if (!fim) {
      return '';
    } else {
      const data = moment(fim).format('DD/MM/YYYY');

      return data;
    }
  }

  checarTelemarketing(): void {
    this.ocultar = ['fechar', 'salvar'];

    if (this.lista?.length === 1) {
      this.formulario = this.data?.campanha?.perguntas;

      const resumo = this.lista[0];

      const check = this.data?.resumo === resumo?.id && resumo?.tipo === this.data?.tipoResumo;

      if (check) {
        this.data?.resposta?.map((res) => {
          if (res?.pergunta) {
            this.respostas[res?.pergunta?.id] = res?.resposta;
          }
        });

        if (this.data?.resposta?.length) {
          this.ocultar = ['salvar'];
        }
      } else {
        const sub = this.data?.sub?.find((fin) => {
          if (fin?.resumo === resumo?.id) {
            if (fin?.tipoResumo === resumo?.tipo) {
              return true;
            }
          }
        });

        if (sub?.resposta?.length) {
          sub?.resposta?.map((res) => {
            if (res?.pergunta) {
              this.respostas[res?.pergunta?.id] = res?.resposta;
            }
          });

          this.ocultar = ['salvar'];
        } else {
          this.ocultar = [];
        }
      }
    }
  }

  set(resposta: any, sindicato: any): void {
    this.respostas[resposta] = sindicato.id;
  }

  tratarData(entrada: any): string {
    const trat = moment(entrada).format('DD/MM/YYYY');

    return trat;
  }

  async openFoto(id: any): Promise<void> {
    if (id) {
      const find = this.data?.resposta?.find((res: any) => res?.pergunta?.id === id);

      if (!find?.anexo) {
        return;
      }

      const foto = await lastValueFrom(this._campanhaService.getAnexo(find?.anexo));

      this._matDialog.open(FotoComponent, {
        width: 'auto',
        maxHeight: '90vh',
        data: foto?.content,
      });
    }
  }

  aprovar(valor: boolean): void {
    this.data.aprovado = valor;

    this.fechar(this.data);
  }

  setCord(coordenadas: any, latitude: any, longitue: any): boolean {
    if (!coordenadas) {
      return;
    }

    const coord = JSON.parse(coordenadas);

    if (coord.latitude) {
      latitude.value = coord.latitude;
    }

    if (coord.longitude) {
      longitue.value = coord.longitude;
    }

    return false;
  }

  openMap(coordenadas: any): any {
    if (!coordenadas) {
      return;
    }

    let link = 'https://maps.google.com/?q=';

    const coord = JSON.parse(coordenadas);

    if (!coord?.latitude || !coord?.longitude) {
      return Swal.fire({
        timer: 2000,
        toast: true,
        icon: 'info',
        timerProgressBar: true,
        showConfirmButton: false,
        text: 'Falta dados de coordenada!',
      });
    }

    link += this.processCoord(coord.latitude) + ',' + this.processCoord(coord.longitude);

    window.open(link, '_blank');
  }

  processCoord(coord: string): string {
    if (coord.includes('.')) {
      return coord;
    }

    let position = 2;

    if (coord.includes('-')) {
      position = 3;
    }

    const part1 = coord.substring(0, position);
    const part2 = coord.substring(position);

    return part1 + '.' + part2;
  }

  fechar(dados?: any): void {
    this._matDialogRef.close(dados);
  }

  getResumo(): void {
    this._resumo.selecionado = this.selecionado;

    const avaliar = this._dialog.open(ResumoCursosComponent, {
      width: '45%',
      position: {
        top: '25px',
        right: '25px',
      },
      autoFocus: false,
      maxHeight: '80vh',
      data: {
        campanha: this.campanha
      }
    });

    avaliar.afterClosed().subscribe((selecionado) => {
      if (selecionado) {
        this.setSelecionado(selecionado);
      }
    });
  }

  setSelecionado(selecionado: any): void {
    this.selecionado = selecionado;

    this.ocultar = [];

    this.respostas = [];
    this.formulario = [];
    this.participantesSemVinculo = false;

    if (this.data?.campanha?.participantesSemVinculo) {
      this.participantesSemVinculo = true;
    } else {
      this.formulario = this.data?.campanha?.perguntas;

      const check = this.data?.resumo === selecionado?.id && selecionado?.tipo === this.data?.tipoResumo;
  
      if (check) {
        this.data?.resposta?.map((res) => {
          if (res?.pergunta) {
            this.respostas[res?.pergunta?.id] = res?.resposta;
          }
        });
  
        if (this.data?.resposta?.length) {
          this.data?.resposta?.map((res) => {
            if (res?.pergunta) {
              this.respostas[res?.pergunta?.id] = res?.resposta;
            }
          });
  
          this.ocultar = [];
        }
      } else {
        const progressos = this.progressos?.find((fin) => {
          if (fin?.resumo === selecionado?.id) {
            if (fin?.tipoResumo === selecionado?.tipo) {
              return true;
            }
          }
        });
  
        if (progressos?.resposta?.length) {
          progressos?.resposta?.map((res) => {
            if (res?.pergunta) {
              this.respostas[res?.pergunta?.id] = res?.resposta;
            }
          });
  
          this.ocultar = ['salvar'];
        } else {
          this.ocultar = [];
        }
      }
  
      this.selecionado = selecionado; 
    }

    this._change.markForCheck();
  }

  definirPessoaNaoVinculada(): void {
    this._gerenciadorService.definirPessoaNaoVinculada(this.data).subscribe({
      next: () => {
        this._utils.toast('Feito!', 'Salvo pessoa não vinculada', 'success', true, 5000);
        this.fechar();
      },
      error: () => {
        this._utils.toast('Ops!', 'Ocorreu um erro ao salvar pessoa não vinculada', 'error', true, 5000);
      },
    });
  }

  definirCaixaPostal(): void {
    this._gerenciadorService.definirCaixaPostal(this.data).subscribe({
      next: () => {
        this._utils.toast('Feito!', 'Salvo caixa postal', 'success', true, 5000);
        this.fechar();
      },
      error: () => {
        this._utils.toast('Ops!', 'Ocorreu um erro ao salvar caixaPostal', 'error', true, 5000);
      },
    });
  }

  setDataAgendamento(date: string): void {
    this.data.dataAgendamento = date ? moment(date).format('yyyy-MM-DD') : null;

    if (this.data.dataAgendamento !== 'Data inválida') {
      this._campanhaService.setAgendamento(this.data.id, this.data.dataAgendamento).subscribe(
        () => {
          const updateDados = {
            id: this.data?.id,
            dados: {
              dataAgendamento: this.data.dataAgendamento,
            },
          };

          this.fechar(updateDados);
        },
        () => {
          //
        }
      );
    }
  }

  removerInteresse(): void {
    if (this.data?.id) {
      this._campanhaService.setSemInteresse(this.data.id).subscribe(
        () => {
          const updateDados = {
            id: this.data?.id,
            dados: {
              aprovado: false,
            },
          };

          this.fechar(updateDados);
        },
        () => {
          //
        }
      );
    }
  }

  save(): void {
    if (this.campanha.tipo === 'Telemarketing') {
      this.salvarTelemarketing();
    } else {
      this.salvarRespostas();
    }
  }

  async salvarRespostas(): Promise<any> {
    const valid = this.formulario
      .map((set) => {
        if (set.obrigatorio && !this.respostas[set?.id]) {
          return true;
        }
      })
      .filter((res: any) => res);

    if (valid.includes(true)) {
      return Swal.fire({
        text: 'Responda todas perguntas obrigatorias antes de salvar!',
      });
    }

    const body = Object.keys(this.respostas)
      .map((res) => {
        if (res && this.respostas[res]) {
          return {
            progresso: this.data?.id,
            resposta: this.respostas[res],
            pergunta: { id: Number(res) },
          };
        }
      })
      .filter((res: any) => res);

    await firstValueFrom(this._gerenciadorService.saveResposta(body));

    this._matDialogRef.close()
    this.fechar();
  }

  async salvarTelemarketing(): Promise<any> {
    const valid = this.formulario
      .map((set) => {
        if (set.obrigatorio && !this.respostas[set?.id]) {
          return true;
        }
      })
      .filter((res: any) => res);

    if (valid.includes(true)) {
      return Swal.fire({
        text: 'Responda todas perguntas obrigatorias antes de salvar!',
      });
    }

    const respostas = Object.keys(this.respostas)
      .map((res) => {
        if (res && this.respostas[res]) {
          return {
            resposta: this.respostas[res],
            pergunta: { id: Number(res) },
          };
        }
      })
      .filter((res: any) => res);

    const body = {
      respostas,
      pessoa: this.data?.pessoa,
      resumo: this.selecionado?.id,
      campanha: this.data?.campanha,
      tipoResumo: this.selecionado?.tipo,
    };

    this._gerenciadorService.saveTelemarketing(body).subscribe(
      () => {
        this._utils.toast('Feito!', 'Resposta salva com sucesso', 'success', true, 5000);

        this.respostas = [];
        this.formulario = [];
        this.selecionado = {};
        this.ocultar = ['fechar', 'salvar'];

        this._change.markForCheck();

        this._matDialogRef.close();
      },
      () => {
        this._utils.toast('ERRO!', 'Erro ao salvar resposta ! ', 'error', true, 5000);
      }
    );
  }

  setMunicipio(value) {
    if (value && value.id) {
      this.cliente.municipio = value;
      this.txtMunicipio = `${value.descricao} - ${value.uf}`;
    }
  }

  displayFnMunicipio(value: any): string {
    return value && typeof value === 'object' ? (value.descricao + ' - ' + value.uf) : value;
  }

  displayEscolaridade(value: any): string {
    return this.escolaridades.find(e => e.value === value)?.description;
  }

  setDataNascimento(dataNascimento): void {
    this.cliente.dataNascimento = dataNascimento;
  }
}
