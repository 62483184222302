import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  tap,
  catchError,
  take,
  switchMap,
  map,
  lastValueFrom,
  finalize,
} from 'rxjs';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { ConversaMensagem } from 'app/model/conversa-mensagem';
import { FiltroChat } from './chats/filtros/filtros.component';
import { v4 as uuidv4 } from 'uuid';
import { MensagemAprovada } from 'app/model/mensagem-aprovada';
import { Campanha } from 'app/model/campanha';
import { Conversa } from 'app/model/conversa';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private _conversa: BehaviorSubject<any> = new BehaviorSubject(null);
  private _mensagens: BehaviorSubject<any> = new BehaviorSubject(null);

  private _modo: BehaviorSubject<any> = new BehaviorSubject(null);
  private _conversas: BehaviorSubject<any> = new BehaviorSubject(null);
  private _loading: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(private _httpClient: HttpClient) { }

  get mensagens$(): Observable<any> {
    return this._mensagens.asObservable();
  }

  set mensagens(mensagens: any) {
    this._mensagens.next(mensagens);
  }

  get conversa$(): Observable<any> {
    return this._conversa.asObservable();
  }

  get modo$(): Observable<any> {
    return this._modo.asObservable();
  }

  get conversas$(): Observable<any> {
    return this._conversas.asObservable();
  }

  get loading$(): Observable<any> {
    return this._loading.asObservable();
  }

  set modo(value: string) {
    this._modo.next(value);
  }

  getConversa(pessoaId: number, numero: string): Observable<any> {
    return this._httpClient
      .get(`${environment.api}/conversa/conversaPessoa/${pessoaId}/${numero}`)
      .pipe(
        catchError((error) => {
          console.log(error);

          return null;
        }),
        tap((response: any) => {
          this._conversa.next(response);
        })
      );
  }

  buscarConversas(pessoaId: number): Observable<any> {
    return this._httpClient.get(`${environment.api}/conversa/conversaPessoa/${pessoaId}`)
  }

  setConversa(conversa): void {
    this._conversa.next(conversa)
  }

  getMensagens(
    pessoaId: any,
    numero: string,
    pagina: any,
    preserv?: boolean
  ): Observable<any> {
    return this.mensagens$.pipe(
      take(1),
      switchMap((mensagens) =>
        this._httpClient
          .get<any>(
            `${environment.api}/conversa/mensagensDto/${pessoaId}/${numero}/${pagina}`
          )
          .pipe(
            map((novas) => {
              if (preserv) {
                this._mensagens.next([...mensagens, ...novas]);

                return cloneDeep(novas);
              } else {
                this._mensagens.next(novas);
              }
            })
          )
      )
    );
  }

  mensagensAtendimento(atendimentoId: number): Observable<any> {
    return this._httpClient
      .get<any>(
        `${environment.api}/conversa/mensagensAtendimento/${atendimentoId}`
      )
      .pipe(
        tap((novas) => {
          this._mensagens.next(novas);
        })
      );
  }

  updateStatus(nova: any) {
    const mensagens = cloneDeep(this._mensagens.getValue());

    const index = mensagens.findIndex((men) => men?.id === nova?.id);

    const update = mensagens[index];

    if (update) {
      update.lida = nova?.lida;
      update.entregue = nova?.entregue;
      update.falha = nova?.falha;
    }

    mensagens[index] = update;

    this._mensagens.next(mensagens);
  }

  novaRecebida(nova: any) {
    const mensagens = cloneDeep(this._mensagens.getValue());

    mensagens.unshift(nova);

    this._mensagens.next(mensagens);
  }

  setMensagensLidas(id: any) {
    return this._httpClient.post(
      `${environment.api}/conversa/setMensagensLidas`,
      { id }
    );
  }

  enviarMensagem(conversa: any, message: ConversaMensagem): Observable<void> {
    if (!message.mensagem) {
      message.mensagem = '';
    }

    const temp: any = cloneDeep(message);
    temp.id = uuid();
    temp.temp = true;
    temp.mensagem = message?.mensagem?.trim()
      ? message?.mensagem
      : 'Enviando ...';

    this.novaRecebida(temp);

    this.moveToTop(conversa);

    return this.mensagens$.pipe(
      take(1),
      switchMap((mensagens: any) =>
        this._httpClient
          .post<any>(
            `${environment.api}/conversa/enviarMensagem/${conversa?.id}`,
            message
          )
          .pipe(
            map((nova) => {
              this.attTemp(temp, nova);
            })
          )
      )
    );
  }

  attTemp(temp: any, nova: any) {
    const mensagens = cloneDeep(this._mensagens.getValue());

    const index = mensagens.findIndex((men) => men?.id === temp?.id);

    mensagens[index] = nova;

    this._mensagens.next(mensagens);
  }

  getCampanhas(nome: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (nome.trim() === '') {
        return resolve([]);
      }
      this._httpClient
        .get(`${environment.api}/campanha/complete/${nome}`)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  validarMidiaExiste(hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.api}/conversa/mediabyhash/${hash}`)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  assumirConversa(body) {
    return this._httpClient.post(
      `${environment.api}/conversa/assumirConversa`,
      body
    );
  }

  pausarRetomarConversa(
    idConversa: number,
    dto: {
      pausado: boolean;
    }
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.api}/conversa/pausarRetomarConversa/${idConversa}`,
      dto
    );
  }

  finalizarConversa(
    idConversa: number,
    dto: {
      status: string;
      avaliar: boolean;
      mesaServicoId: number;
      atendimentoMesaId: number;
    }
  ) {
    return this._httpClient.post(
      `${environment.api}/conversa/finalizarConversa/${idConversa}`,
      dto
    );
  }

  saveMidia(body) {
    return this._httpClient.post(
      `${environment.api}/automacao/saveMidia`,
      body
    );
  }

  removerAtendente(idConversa): Observable<any> {
    return this._httpClient
      .delete(`${environment.api}/conversa/removerAtendente/${idConversa}`)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap({})
      );
  }

  async getMymeType(link: string) {
    link = link?.replace('/thumbnail/', '/media/');

    try {
      const response = await axios.head(link);

      const type = response.headers['content-type'];

      if (type) {
        return type;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Erro ao obter o tipo de conteúdo:', error);
      return null;
    }
  }

  getConversas(
    pagina: any,
    modo: string,
    nome: string,
    filtro: FiltroChat,
    preserv?: boolean
  ): Observable<any> {
    const uuid = uuidv4();

    if (!preserv) {
      this.injectLoad(uuid);
    }

    if (!nome || !nome?.trim()?.length) {
      nome = null;
    }
    if (nome?.startsWith("#")) {
      nome = nome.replace("#", "%23");
    }
    return this.conversas$.pipe(
      take(1),
      switchMap((conversas: any) =>
        this._httpClient
          .post<any>(
            `${environment.api}/conversa/getConversas/${pagina}/${modo}/${nome}`, filtro
          )
          .pipe(
            map((novas) => {
              if (preserv) {
                this._conversas.next([...conversas, ...novas]);
              } else {
                this._conversas.next(novas);
              }
            }),
            finalize(() => {
              this.removeLoad(uuid);
            })
          )
      )
    );
  }

  injectLoad(uuid: string): void {
    const itens = this._loading.getValue();

    itens.push(uuid);

    this._loading.next(itens);
  }

  removeLoad(uuid: string): void {
    const itens = this._loading.getValue();

    itens.splice(
      itens.findIndex((fin: any) => fin === uuid),
      1
    );

    this._loading.next(itens);
  }

  removeConversa(pessoaId: number, de: string): void {
    const conversas: any[] = cloneDeep(this._conversas.getValue());

    const index = conversas.findIndex(
      (cv: any) => cv?.id === pessoaId && cv?.de === de
    );

    conversas?.splice(index, 1);

    this._conversas.next(conversas);
  }

  loadMore(event: any, pagina: number, modo: string, nome: string): void {
    this.getConversas(pagina, modo, nome, new FiltroChat(), true).subscribe(
      () => { },
      () => { },
      () => {
        event.target.complete();
      }
    );
  }

  moveToTop(conversa: any): void {
    const olds: any[] = this._conversas.getValue();

    const index = olds?.findIndex(
      (fin: any) => fin?.de === conversa?.de && conversa?.pessoa?.id === fin?.id
    );

    if (index !== -1) {
      const old = olds.splice(index, 1);

      olds.unshift(old.pop());

      this._conversas.next(olds);
    }
  }

  save(pessoa): Observable<any> {
    return this._httpClient.post(`${environment.api}/pessoa/editarPessoaConversa`, pessoa)
      .pipe(
        map(async (conversa: any) => {
          const conversas = this._conversas.getValue();
          if (conversas?.length) {
            const idx = conversas.findIndex(c => c.cid === conversa.id)
            if (idx > -1) {
              const c = conversas[idx]
              c.id = conversa.pessoa.id
              c.nome = conversa.pessoa.nome
              conversas[idx] = c
              await lastValueFrom(this.getConversa(c.id, c.de))
              this._conversas.next(conversas)
            }
          }
        })
      )
  }

  buscarCampanhas(filtro): Observable<Campanha[]> {
    return this._httpClient.post<Campanha[]>(`${environment.api}/campanha/all`, filtro);
  }

  buscarMensagemAprovada(): Observable<MensagemAprovada[]> {
    return this._httpClient.get<MensagemAprovada[]>(`${environment.api}/mensagemAprovada`);
  }

  novaConversa(body): Observable<Conversa> {
    return this._httpClient.post<Conversa>(`${environment.api}/conversa/iniciarConversa`, body );
  }
}
