import moment from 'moment';
import { CampanhaJob } from './CampanhaJob';

export class Campanha {
  public id: number;
  public nome: string;
  public telefoneOrigem: string;
  public mensagem: {
    id: number;
    nome: string;
  };
  public sms: String;
  public tipo: string;
  public criadoEm: Date;
  public ativo: boolean;
  public fimCurso: Date;
  public perguntas: any;
  public enviadoEm: Date;
  public objetivo: string;
  public job: CampanhaJob;
  public inicioCurso: Date;
  public naoEnviar: boolean;
  public programada: boolean;
  public automacao: boolean;
  public tipoVinculo: String;
  public conversaAutomacao: any;
  public contabilizarDash: boolean;
  public formWeb: boolean;
  public grupoFiliacao: {
    id: number;
    nome: string;
  };
  public pesquisaAtiva: boolean;
  public fraseologiaAbertura: String;
  public fraseologiaEncerramento: String;
  public status: String;
  public participantesSemVinculo: String;

  constructor(campanha?: Campanha) {
    if (campanha) {
      this.id = campanha.id;
      this.nome = campanha.nome;
      this.telefoneOrigem = campanha.telefoneOrigem;
      this.mensagem = campanha.mensagem;
      this.criadoEm = campanha.criadoEm
        ? new Date(
            campanha.criadoEm.toString().replace(/-/g, '/').replace(/T.+/, '')
          )
        : null;
      this.enviadoEm = campanha.enviadoEm
        ? new Date(
            campanha.enviadoEm.toString().replace(/-/g, '/').replace(/T.+/, '')
          )
        : null;
      this.naoEnviar = campanha.naoEnviar;
      this.contabilizarDash = campanha.contabilizarDash;
      this.objetivo = campanha.objetivo;
      this.tipo = campanha.tipo;
      this.ativo = campanha.ativo;
      this.perguntas = campanha.perguntas;
      this.tipoVinculo = campanha.tipoVinculo;
      this.sms = campanha.sms;
      this.programada = campanha.programada;
      this.job = campanha.job;
      this.fimCurso = moment(campanha.fimCurso).toDate();
      this.inicioCurso = moment(campanha.inicioCurso).toDate();
      this.conversaAutomacao = campanha.conversaAutomacao;
      this.automacao = campanha.automacao;
      this.formWeb = campanha.formWeb;
      this.grupoFiliacao = campanha.grupoFiliacao;
      this.pesquisaAtiva = campanha.pesquisaAtiva;
      this.fraseologiaAbertura = campanha.fraseologiaAbertura;
      this.fraseologiaEncerramento = campanha.fraseologiaEncerramento;
      this.status = campanha.status;
      this.participantesSemVinculo = campanha.participantesSemVinculo;
    } else {
      this.id = null;
      this.nome = null;
      this.telefoneOrigem = null;
      this.mensagem = null;
      this.criadoEm = null;
      this.enviadoEm = null;
      this.naoEnviar = null;
      this.contabilizarDash = null;
      this.tipo = null;
      this.ativo = null;
      this.fimCurso = null;
      this.inicioCurso = null;
      this.tipoVinculo = null;
      this.sms = null;
      this.programada = null;
      this.job = null;
      this.conversaAutomacao = null;
      this.automacao = null;
      this.formWeb = null;
      this.grupoFiliacao = null;
      this.pesquisaAtiva = null;
      this.fraseologiaAbertura = null;
      this.fraseologiaEncerramento = null;
      this.status = null;
      this.participantesSemVinculo = null;
    }
  }
}
