import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import { VinculoMeioRural } from './model/vinculo-meio-rural';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _vinculoMeioRural: BehaviorSubject<VinculoMeioRural[]> = new BehaviorSubject([]);
  private _escolaridade: BehaviorSubject<VinculoMeioRural[]> = new BehaviorSubject([]);

  constructor(private _httpClient: HttpClient) { }

  get vinculoMeioRural$(): Observable<VinculoMeioRural[]> {
    return this._vinculoMeioRural.asObservable();
  }

  get escolaridade$(): Observable<any[]> {
    return this._escolaridade.asObservable();
  }

  getAllVinculoMeioRural(): Observable<VinculoMeioRural[]> {
    return this._httpClient.get<VinculoMeioRural[]>(`${environment.api}/vinculoMeioRural`).pipe(
      catchError((error) => {
        return of(this._vinculoMeioRural.next([]));
      }),
      tap((vinculoMeioRural: VinculoMeioRural[]) => {
        this._vinculoMeioRural.next(vinculoMeioRural);
      })
    );
  }

  getAllEscolaridade(): Observable<any[]> {
    return this._httpClient.get<any[]>(`${environment.api}/enum/br.org.sistemafaeg.infosindical.model.enums.EnumEscolaridade`).pipe(
      catchError((error) => {
        return of(this._escolaridade.next([]));
      }),
      tap((escolaridade: any[]) => {
        this._escolaridade.next(escolaridade);
      })
    );
  }
}
